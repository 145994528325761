<template>
    <div>
        <section class="block">
            <div class="block__text">Descubre a los 37 participantes</div>
            <button class="app__button mt-2 ml-a">
                <router-link to="/countries">
                    <span class="button__text mr-1">Entrar</span>
                    <svg-icon type="mdi" :path="mdiArrowRight" :size="16"></svg-icon>
                </router-link>
            </button>
        </section>
        <section class="block block--secondary">
            <div class="block__text">Crea tu propio scoreboard para la gran final</div>
            <button class="app__button mt-2 ml-a">
                <router-link to="/europarty">
                    <span class="button__text mr-1">Entrar</span>
                    <svg-icon type="mdi" :path="mdiArrowRight" :size="16"></svg-icon>
                </router-link>
            </button>
        </section>
        <section class="block block--terciary">
            <div class="block__text">Revisa los resultados de la comunidad</div>
            <button class="app__button mt-2 ml-a">
                <router-link to="/scoreboard">
                    <span class="button__text mr-1">Entrar</span>
                    <svg-icon type="mdi" :path="mdiArrowRight" :size="16"></svg-icon>
                </router-link>
            </button>
        </section>
    </div>
</template>
  
<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiArrowRight } from '@mdi/js'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',
    components: {
        SvgIcon
    },
    data() {
        return {
            mdiArrowRight
        }
    }
  }
</script>

<style lang="scss" scoped>
    .block{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.5rem;
        color: $c-block-text;
        background-color: $c-block-bg;

        &__text{
            line-height: 1.4;
        }

        &--secondary{
            color: $c-block-text-2;
            background-color: $c-block-bg-2;
        }

        &--terciary{
            color: $c-block-text-3;
            background-color: $c-block-bg-3;

            .app__button{
                background: $c-button-2;

                a{
                    color: $c-button-text-2;
                }
            }
        }
    }
</style>