<template>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <section class="country__list">
      <div class="country__filters">
        <button class="country__filter" :class="{'country__filter--active': filter === null}" @click="filter = null">Todos</button>
        <button class="country__filter" :class="{'country__filter--active': filter === 1}" @click="filter = 1">1ª Semi.</button>
        <button class="country__filter" :class="{'country__filter--active': filter === 2}" @click="filter = 2">2ª Semi.</button>
        <button class="country__filter" :class="{'country__filter--active': filter === 0}" @click="filter = 0">Final</button>
      </div>
      <div v-show="inFilter(country)" class="country" v-for="country, key in countries" :key="'country' + key" @click="go(country)">
        <div class="country__semifinal" :class="{'country__semifinal--final': !country.semifinal}">
          <svg-icon v-if="!country.semifinal" class="fixsvg mr-d25" type="mdi" :path="mdiStar" :size="12"></svg-icon>
          <span v-if="country.semifinal">{{ country.semifinal }}ª Semi.</span>
          <span v-else>Final</span>
        </div>
        <div class="country__photo">
          <img class="photo" :src="'/artists/' + country.flag + '.webp'">
        </div>
        <div class="country__info">
          <div class="country__flag">
            <img class="photo" :src="'/43/' + country.flag + '.svg'" />
          </div>
          <div class="country__text">
            <div class="country__name">{{ country.country_name }}</div>
            <div class="country__artist">{{ country.name }}</div>
          </div>
          <div class="country__go">
            <svg-icon class="fixsvg" type="mdi" :path="mdiArrowRight" :size="24"></svg-icon>
          </div>
        </div>
      </div>
    </section>
    <Loading :loading="loading" />
</template>
  
<script>
  // import Config from './config'
  import SvgIcon from '@jamescoyle/vue-icon'
  import { mdiArrowRight } from '@mdi/js'
  import { mdiStar } from '@mdi/js'
  import Loading from '../../components/Loading.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Countries',
    data() {
      return {
        countries: [],
        mdiArrowRight,
        mdiStar,
        filter: null,
        loading: {
          loading: false
        }
      }
    },
    components: {
      SvgIcon,
      Loading
    },
    async created(){
      // this.axios.get("http://127.0.0.1:8030/country" , {
        this.loading.loading = true;
      await this.axios.get("https://api.jciaurrant.com/country" , {
            headers: {
              "Content-Type": "application/json",
            }
        })
        .then(response => {
          this.countries = response.data;
        })
       .catch(function (error) {
          console.log(error);
      });
      this.loading.loading = false;
    },
    methods: {
      go(country){
        this.$router.push('/countries/' + country.flag);
      },
      inFilter(country){
        let result = false;
        if(this.filter === null){
          result = true;
        }else{
          if(this.filter > 0){
            result = this.filter === country.semifinal;
          }else{
            result = !!country.final_position;
          }
        }
        return result;
      }
    }
  }
</script>

<style scoped lang="scss">
    .country{
      background-color: $c-info-country;
      border-radius: 5px;
      overflow: hidden;
      margin: 2rem;
      position: relative;

      &__photo{
        .photo{
          display: block;
          object-fit: cover;
          margin-left: -1.4rem;
        }
      }

      &__info, &__text{
        display: flex;
      }

      &__info{
        padding: .75rem 1rem;
      }

      &__flag{
        margin-top: auto;
        margin-bottom: auto;
        width: 3rem;
        .photo{
          display: block;
          width: 100%;
          box-shadow: 0 1px 2px $c-info-country-fog;
        }
      }

      &__text{
        color: $c-info-country-txt;
        flex-direction: column;
        margin: .5rem 1rem;
      }

      &__name{
        display: block;
        font-weight: 500;
        margin-bottom: .25rem;
      }

      &__artist{
        font-size: .9rem;
      }

      &__go{
        color: $c-info-country-txt;
        display: flex;
        margin-left: auto;

        svg{
          display: block;
          margin: auto;
        }
      }

      &__semifinal{
        border: 1px solid $c-semi-border;
        background: $c-semi-bg;
        font-size: .7rem;
        position: absolute;
        padding: .15rem .5rem;
        top: .5rem;
        right: .5rem;
        border-radius: .7rem;
        color: $c-semi-text;
        display: flex;
        flex-direction: row;

        &--final{
          border: $c-semi-final;
          // color: $c-semi-final;
          background: $c-semi-final-bg;
          
          // svg{
          //   color: $c-semi-final;
          // }
        }
      }

      &__filters{
        display: flex;
        margin: 0 2rem;
      }

      &__filter{
        background: none;
        border: none;
        outline: none;
        color: $c-button-text;
        text-transform: uppercase;

        &--active{
          font-weight: 500;
          border-bottom: 2px solid $c-button-text;
        }
      }
    }
</style>