<template>
  <button class="app__button app__button--nobg ml-2 mr-a">
      <router-link to="/countries">
          <svg-icon type="mdi" :path="mdiArrowLeft" :size="16"></svg-icon>
          <span class="button__text ml-d5">Países</span>
      </router-link>
  </button>
  <div class="country mr-2 ml-2 mb-2" v-if="country && song">
    <div class="country__image">
      <img :src="'/artists/' + country.flag + '.webp'"/>
    </div>
    <div class="country__title mt-1">
      <div class="country__flag">
        <img :src="'/43/' + country.flag + '.svg'"/>
      </div>
      <div class="country__name ml-1">{{ country.name }}</div>
    </div>
    <div class="country__data mt-1">
      <div class="country__label mr-d5">Canción:</div>
      <div class="country__value">{{ song.name }}</div>
    </div>
    <div class="country__data mt-d5">
      <div class="country__label mr-d5">Artista:</div>
      <div class="country__value">{{ song.singer }}</div>
    </div>
    <!-- <div class="country__data mt-d5">
      <div class="country__label mr-d5">Autor:</div>
      <div class="country__value">{{ song.author }}</div>
    </div> -->
    <div v-if="song.semifinal" class="country__data mt-d5">
      <div class="country__label mr-d5">Semifinal:</div>
      <div class="country__value">{{ song.semifinal }}ª semifinal</div>
    </div>
    <div v-else class="country__data mt-d5">
      <div class="country__label">
        <span class="mr-d25">Big Five</span>
        <svg-icon class="fixsvg" type="mdi" :path="mdiStar" :size="16"></svg-icon>
      </div>
    </div>
    <div class="country__youtube mt-2">
      <iframe style="border-radius:12px" :src="song.spotify" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
    <div class="country__youtube mt-2">
      <iframe width="100%" height="250px" :src="song.url_youtube" :title="song.name" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
  <Loading :loading="loading" />
</template>
  
<script>
  import SvgIcon from '@jamescoyle/vue-icon'
  import { mdiArrowLeft } from '@mdi/js'
  import { mdiStar } from '@mdi/js'
  import Loading from '../../components/Loading.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Country',
    components: {
      SvgIcon,
      Loading
    },
    data() {
      return {
        mdiArrowLeft,
        mdiStar,
        country: null,
        song: null,
        loading: {
          loading: false
        }
      }
    },
    async mounted(){
      const flag = this.$route.params && this.$route.params.flag ? this.$route.params.flag : false; 

      if(flag){
        this.loading.loading = true;
        // this.axios.get("http://127.0.0.1:8030/country/" + flag , {
        await this.axios.get("https://api.jciaurrant.com/country/" + flag , {
              headers: {
                "Content-Type": "application/json",
              }
          })
          .then(response => {
            this.country = response.data[0];
          })
         .catch(function (error) {
            console.log(error);
        });

        // this.axios.get("http://127.0.0.1:8030/song/" + flag , {
        await this.axios.get("https://api.jciaurrant.com/song/" + flag , {
              headers: {
                "Content-Type": "application/json",
              }
          })
          .then(response => {
            this.song = response.data[0];
          })
         .catch(function (error) {
            console.log(error);
        });
        this.loading.loading = false;
      }
      
    },
  }
</script>

<style scoped lang="scss">
  .country{
    display: flex;
    flex-direction: column;

    &__youtube{
      margin-left: -2rem;
      margin-right: -2rem;
    }
    
    &__image{
      margin: 0 -2rem;
      img{
        display: block;
        width: 100%;
      }
    }

    &__title{
      display: flex;
      align-items: center;
      align-content: center;
    }

    &__name{
      font-size: 2.5rem;
      font-weight: 500;
    }

    &__flag{
      width: 3rem;
      padding-top: .2rem;

      img{
        display: block;
        width: 100%;
      }
    }

    &__data{
      display: flex;
    }
    &__label{
      font-weight: 500;
      display: flex;
      align-content: center;
    }
  }
</style>