<template>
    <div class="mr-2 ml-2">
        <div class="title mb-2">Sobre Europarty</div>
        <div class="mb-2 description">
            <p class="mb-d5">Todo empezó un año que nos íbamos a juntar muchos amigos a ver Eurovisión. Empezamos a plantear retos, decoración, El Drink contest, porras... y se me ocurrió, ¿Por qué no montar nuestra propia aplicación para votar nuestros favoritos?</p>
            <p class="mb-d5">Y así es como empecé este reto que cada año voy mejorando y aportándole todo lo que voy aprendiendo en mi profesión.</p>
            <p class="mb-d5">El objetivo final es que nos sintamos parte del festival y crear una comunidad eurofan, amante de la música y con ganas de fiesta y pasarlo bien.</p>
            <p class="mb-d5">¿Qué nos deparará el año que viene? ¿Qué mejoras tendremos en la aplicación? Tengo muchas ideas en mente. Espero que disfutes de Europarty.</p>
        </div>
        <div class="title mb-2">Sobre Mí</div>
        <div class="fix mb-1">
            <img src="me.jpg" alt="Yo" class="image">
        </div>
        <div class="mb-2 description">
            <p class="mb-d5">¡Hola! Soy Javier, un programador web de Pamplona y, como me imagino que te habrás dado cuenta, soy eurofan.</p>
            <p class="mb-d5">Llevo siendo eurofan desde hace muchos años, y aunque ya no le puedo dedicar el tiempo que le dedicaba, todo el cariño por este festival se lo doy a esta aplicación.</p>
            <p class="mb-d5">Cuando era txiki recuerdo que existía un programa donde motabas un Scoreboard con los países que quisieras y la comunidad eurofan fue creando plantillas de estilos y mejorando el programa. Siempre pensaba lo guay que era poder hacer eso y ojalá poder hacerlo algún día. Pues ya lo he conseguido, esto es Europarty y es para todos vosotros.</p>
        </div>
    </div>
</template>
  
<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',
    data() {
        return {
        }
    }
  }
</script>

<style lang="scss" scoped>
    .title{
        font-weight: 400;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: $c-title;
    }

    .description{
        line-height: 1.3;
        text-align: justify;
    }

    .fix{
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .image{
        display: block;
        width: 100%;
    }
</style>