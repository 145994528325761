<template>
  <div class="menu" :class="{'menu--open': open}">
    <button class="menu__button" @click.prevent="open = !open">
      <svg-icon style="color:white" type="mdi" :path="icon" :size="32"></svg-icon>
    </button>
    <ul class="menu__list" :class="{'menu__list--open': open}">
      <li v-show="item.visible" class="menu__item" v-for="item, key in items" :key="'link' + key" :class="{'menu__item--active': activeLink(item.url)}">
        <router-link :to="item.url" @click="open = !open">{{ item.text }}</router-link>
      </li>
    </ul>
  </div> 
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiClose } from '@mdi/js'
import { mdiMenu } from '@mdi/js'

export default {
  name: 'LateralMenu',
  props: {},
  components: {
    SvgIcon
  },
  data() {
    return {
      open: false,
      items: [
        {
          url: "/",
          text: "Inicio",
          visible: true
        },
        {
          url: "/countries",
          text: "Países",
          visible: true
        },
        {
          url: "/europarty",
          text: "Europarty",
          visible: true
        },
        {
          url: "/vote",
          text: "Vota",
          visible: !!localStorage.getItem('currentEuroparty')
        },
        {
          url: "/scoreboard",
          text: "Scoreboard",
          visible: true
        },
        {
          url: "/about",
          text: "Sobre Europarty",
          visible: true
        },
      ]
    }
  },
  computed: {
    icon(){
      return this.open ? mdiClose : mdiMenu;
    }
  },
  methods: {
    activeLink(url){
      let result = false;
      if(this.$route.fullPath === url){
        result = true;
      }

      if(url.includes('/vote') && this.$route.fullPath.includes('/vote')){
        result = true;
      }

      if(url.includes('/scoreboard') && this.$route.fullPath.includes('/scoreboard')){
        result = true;
      }

      return result;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .menu{
    position: relative;
    width: 100%;

    &--open{
      &::before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        width: 100vw;
        height: 100vh;
      }
    }

    &__list{
      position: fixed;
      top: 0;
      right: 0;
      width: 0;
      height: 100vh;
      overflow: hidden;
      padding-top: 5rem;

      display: flex;
      flex-direction: column;

      background: $c-menu-bg;


      &--open{
        min-width: 70vw;
        z-index: 2;
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: 1.0);
      }
    }

    &__button{
      display: flex;
      background-color: transparent;
      border: 0;
      outline: 0;
      position: fixed;
      top: 1rem;
      right: 2rem;
      z-index: 3;
    }

    &__item{
      display: flex;
      justify-content: center;
      align-items: center;
      
      &--active{
        background-color: $c-link-active;
      }
      
      a{
        display: block;
        width: 100%;
        text-align: right;
        padding: 1rem;
        padding-right: 2.5rem;
        color: $c-text;
        text-decoration: none;
        font-variant: small-caps;
        text-transform: uppercase;
      }
    }
  }

</style>
