<template>
    <div :class="{'padding-bottom': sendVotesShow}" v-if="this.europartyToken">
        <div class="votes ml-1 mr-1 mb-2">
            <ul class="votes__list">
                <li v-for="vote in votes" :key="vote.value" class="vote"
                    :class="{ 'vote--active': isCurrentVote(vote), 'vote--done': isDoneVote(vote.value) }"
                    @click="selectVote(vote.value)">{{ vote.value }}</li>
            </ul>
        </div>
        <div v-show="showGoTo()" class="ml-1 mr-1 mb-2 center">
            {{ currentVote }} point{{ currentVote > 1 ? "s": "" }} {{ currentVote === 1 ? "goes": "go" }} to...
        </div>
        <div class="vcountries mr-1d5 ml-1d5">
            <transition-group name="flip" tag="ul" class="vcountries__list">
                <li class="vcountry" @click="vote(country)" v-for="country in countries" :key="country.flag">
                    <img :src="'/flags/' + country.flag + '.svg'" :alt="country.flag" class="vcountry__flag">
                    <div class="vcountry__text">
                        <span class="vcountry__name">{{ country.country_name }}</span>
                        <span class="vcountry__title">{{ country.name }}</span>
                    </div>
                    <span class="vcountry__vote" :class="{ 'vcountry__vote--visible': country.points > 0 }" data-point=""
                        :data-code="country.code">{{ country.points }}</span>
                    <!-- <transition class="vcountry__vote" v-if="country.showPoints">{{ country.points }}</transition> -->
                </li>
            </transition-group>
        </div>
        <div class="vform vform--fixed" v-show="sendVotesShow">
            <div class="row">
                <input type="text" v-model="playerName" placeholder="NAME" class="vform__input">
                <button class="app__button" @click="sendVotes()">VOTE!</button>
            </div>
        </div>
        <!-- <vue-recaptcha v-show="showRecaptcha"
          sitekey="6LcQ9M0lAAAAAK3c_mJ1058KdJaTmV7A99Wa8E7K"
          size="normal" 
          theme="light"
          hl="tr"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha">
        </vue-recaptcha> -->
    </div>
    <div v-else>
        <div class="center ml-2 mr-2 mt-1">Vaya, parece que no tienes una partida cargada. ¿Te animas a crear una?</div>

        <button class="app__button mt-2 ml-a mr-a">
            <router-link to="/europarty">
                <span class="button__text">Crear partida</span>
            </router-link>
        </button>
    </div>
    <Loading :loading="loading" />
</template>
  
<script>
// import vueRecaptcha from 'vue3-recaptcha2';
import Loading from '../../components/Loading.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Vote',
    components: {
    //   vueRecaptcha,
      Loading
    },
    data() {
        return {
            showRecaptcha: false,
            recaptchaResponse:null,
            homes: [],
            audios: {
                points: new Audio('points.mp3'),
                finish: new Audio('twelve.mp3')
            },
            currentVote: 1,
            hide: false,
            countries: [],
            europarty: null,
            europartyToken: null,
            votes: [
                { value: 1 },
                { value: 2 },
                { value: 3 },
                { value: 4 },
                { value: 5 },
                { value: 6 },
                { value: 7 },
                { value: 8 },
                { value: 10 },
                { value: 12 },
            ],
            points: [],
            sendVotesShow: false,
            playerName: "",
            loading: {
                loading: false
            }
        }
    },
    computed: {

    },
    async created(){
        if(this.$route.params?.token){
            localStorage.setItem("currentEuroparty", this.$route.params?.token);
        }
        
        // 1. Comprobamos que la partida que tenemos almacenada existe
        this.europartyToken = localStorage.getItem("currentEuroparty");
        if(this.europartyToken && !this.hasVotes()){
            this.loading.loading = true;
            let out = false;
            await this.axios.get("https://api.jciaurrant.com/europarty/" + this.europartyToken , {
                headers: {
                  "Content-Type": "application/json",
                }
            })
            .then(response => {
              this.europarty = response.data;

              if(!this.europarty){
                localStorage.removeItem("currentEuroparty");
                out = true;
            }

            })
            .catch(function (error) {
                if(error.response.status === 404){
                    localStorage.removeItem("currentEuroparty");
                    out = true;
                }
            });  

            if(out){
                this.loading.loading = false;
                this.$router.push('/');
                return true;
            }

          // this.axios.get("http://127.0.0.1:8030/country" , {
          await this.axios.get("https://api.jciaurrant.com/country/final" , {
                headers: {
                  "Content-Type": "application/json",
                }
            })
            .then(response => {
                this.countries = response.data.map(c => { 
                    c.points = 0;
                    c.showPoints = null;
                    return c;
                });
            })
           .catch(function (error) {
              console.log(error);
          });
          this.loading.loading = false;
        }else if(this.hasVotes()){
            this.$router.push("/scoreboard/" + this.europartyToken);
        }
    },
    methods: {
        showGoTo(){
            return this.points.length < 10;
        },
        hasVotes(){
            let europarties = JSON.parse(localStorage.getItem("europarties") || "[]");
            let result = false;
            const party = this.europartyToken;

            europarties.forEach(europarty => {
                if(europarty.party === party){
                    result = true;
                }
            });

            return result;
        },
        getCountryPoints(points){
            return this.countries.find(c => { return c.points == points; }).id;
        },
        async sendVotes(){
            if(!this.recaptchaResponse && this.playerName) { // TODO: DESNEGAR
                this.loading.loading = true;
                const token = this.generateRandomString(10);
                this.token = token;
                const body = {
                    name: this.playerName,
                    token,
                    europarty: this.europarty[0].id,
                    "12s": this.getCountryPoints(12),
                    "10s": this.getCountryPoints(10),
                    "8s": this.getCountryPoints(8),
                    "7s": this.getCountryPoints(7),
                    "6s": this.getCountryPoints(6),
                    "5s": this.getCountryPoints(5),
                    "4s": this.getCountryPoints(4),
                    "3s": this.getCountryPoints(3),
                    "2s": this.getCountryPoints(2),
                    "1s": this.getCountryPoints(1),
                }

                // this.axios.post("http://127.0.0.1:8030/europarty" , body, {
               await this.axios.post("https://api.jciaurrant.com/player" ,body, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(response => {
                    if(response.data.response){
                        let europarties = JSON.parse(localStorage.getItem("europarties") || "[]");
                        europarties.push({"player": token, "party": this.europartyToken});
                        localStorage.setItem("europarties", JSON.stringify(europarties));
                        
                        this.sendVotesShow = false;
                        this.$router.push('/scoreboard/' + this.europartyToken);
                    } else {
                        console.log("ERROR"); // TODO:
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                this.loading.loading = false;
            }
        },
        isCurrentVote(vote) {
            return vote.value == this.currentVote;
        },
        selectVote(vote) {
            this.currentVote = vote;
            let country = this.countries.find((item) => { return item.points === vote});
            if (country && country.flag) {
                this.removeVote(country);
            }
        },
        isDoneVote(vote) {
            return this.points.indexOf(vote) >= 0;
        },
        vote(country) {
            if (country.points <= 0) {
                if (this.points.length < 10) {
                    this.countries.map(c => {
                        if(c.country_name === country.country_name){
                            c.points = this.currentVote;
                        }
                        return c;
                    })
                    // country.points = this.currentVote;
                    this.points.push(this.currentVote)
                    this.currentVote = this.setNextVote(this.currentVote, this.points);

                    if (this.points.length == 10) {
                        this.audios.finish.play();
                    } else {
                        this.audios.points.play();
                    }
                }
            } else {
                this.removeVote(country);
            }
        },
        moveCountries(){
            this.$nextTick(() => {
                this.countries = this.countries.sort( (c, d) => { return c.points > d.points ? -1 : 1; })
            })
        },
        removeVote(country) {
            this.currentVote = country.points + 0;
            this.points.splice(this.points.indexOf(country.points), 1);
            this.countries.map(c => {
                if(c.country_name === country.country_name){
                    c.points = 0;
                    c.showPoints = null;
                }
                return c;
            })
            // this.moveCountries();
        },
        setNextVote(currentVote, points){
            if(points.length == 10){
                return 0;
            }else{
                let result = 0;

                if(currentVote == 12){
                    result = 1;
                }else{
                    result = currentVote + 1;
                    if(currentVote == 8 || currentVote == 10){
                        result++;
                    }
                }

                if(points.indexOf(result) < 0){
                    return result;
                }else{
                    return this.setNextVote(result, points);
                }
            }
        },
        generateRandomString(length) {
            let result = '';
            const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        recaptchaVerified(response) {
            console.log(response);
            this.recaptchaResponse = response;
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
            console.error('EL RECAPTCHA HA FALLADO');
        },
    },
    watch: {
        "points": {
            async handler() {
                setInterval(() => {
                    this.moveCountries();
                }, 1000);
                if (this.points.length == 10) {
                    this.sendVotesShow = true;
                } else {
                    this.sendVotesShow = false;
                }
            },
            deep: true
        }
    },
}
</script>

<style lang="scss" scoped></style>