<template>
    <section class="main-content">
      <div class="mb-1">
        Monta tu propio scoreboard para votar con tus amigos en la fiesta de la músca del año.
      </div>
      <div class="mb-1">
        Nombra tu Europarty, comparte el enlace con tus amigos y ¡a votar!
      </div>
      <hr class="mb-3">
      <div class="mb-1" v-show="hasParty()">
        Ya tienes generada una Europarty en tu movil.
      </div>
      <button v-show="hasParty()" class="app__button mr-a ml-a mb-2" @click="$router.push('/vote')">IR A MI EUROPARTY</button>
      <div class="mb-1" v-show="error">
        No ha sido posible crear tu Europarty. Espera un poco y vuelve a probar.
      </div>
      <div class="form" v-show="!created && !hasParty()">
        <div class="form__item">
          <label for="europarty" class="form__label mb-d5">Nombra a tu Europarty</label>
          <input type="text" for="europarty" class="form__input" v-model="euroname"/>
        </div>
        <!-- <vue-recaptcha v-show="showRecaptcha"
          sitekey="6LcQ9M0lAAAAAK3c_mJ1058KdJaTmV7A99Wa8E7K"
          size="normal" 
          theme="light"
          hl="tr"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha">
        </vue-recaptcha> -->
        <button class="app__button mr-a ml-a mt-1" @click="sendForm()">CREAR</button>
      </div>
      <div class="form" v-show="created">
        <div class="form__item form__item--lined">
          <input type="text" for="europarty" class="form__input" v-model="link" disabled/>
          <button class="app__button mr-a ml-a mt-1" @click="share()">COPIAR</button>
          <span v-show="copied"></span>
        </div>
      </div>
      <button v-show="created" class="app__button mr-a ml-a mt-1" @click="$router.push('/vote')">EMPEZAR A VOTAR</button>
      <!-- TODO: Conseguir mostrar en el menú la sección de votar -->
    </section>
</template>
  
<script>
// import vueRecaptcha from 'vue3-recaptcha2';
import confetti from "canvas-confetti";

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Europarty',
    components: {
      // vueRecaptcha
    },
    data(){
      return {
        showRecaptcha: false,
        euroname: "",
        recaptchaResponse:null,
        created: false,
        link: "",
        copied: false,
        token: "",
        error: false,
        currentCreation: false
      }
    },
    methods: {
      hasParty(){
        return !!localStorage.getItem('currentEuroparty') && !this.currentCreation;
      },
      share(){
        this.copied = false;
        if(navigator.clipboard.writeText(this.link)){
          confetti();
          this.copied = true;
        }
      },
      sendForm(){
        this.error = false;
        if(!this.recaptchaResponse && this.euroname){ // TODO: Desnegar la condicion
          const token = this.generateRandomString(8);
          this.token = token;
          const body = {
            name: this.euroname,
            token
          }

          // this.axios.post("http://127.0.0.1:8030/europarty" , body, {
          this.axios.post("https://api.jciaurrant.com/europarty" ,body, {
              headers: {
                "Content-Type": "application/json"
              }
          })
          .then(response => {
            console.log("PARTY CREADA", response);
            this.currentCreation = true;
            localStorage.setItem("currentEuroparty", this.token);
            this.link = "https://europarty.jciaurrant.com/vote/" + token;
            this.created = true;
          })
         .catch(function () {
            this.error = true;
        });
        }
      },
      recaptchaVerified(response) {
        console.log(response);
        this.recaptchaResponse = response;
      },
      recaptchaExpired() {
        this.$refs.vueRecaptcha.reset();
      },
      recaptchaFailed() {
        console.error('EL RECAPTCHA HA FALLADO');
      },
      generateRandomString(length) {
        let result = '';
        const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
    }
  }
</script>

<style scoped lang="scss">

  .app__button{
    padding: .5rem 1rem;

    a{
      padding: 0;
      line-height: 0;
    }
  }
  .form{
    &, &__item{
      display: flex;
      flex-direction: column;
    }

    &__label{
      font-weight: 500;
    }

    &__label, &__input{
      display: block;
      text-transform: uppercase;
    }

    &__input{
      border: 1px solid $c-input-br;
      color: $c-input-text;
      background: $c-input-bg;
      padding: .5rem;

      &:focus{
        outline: none;
      }
    }
  }
</style>