<template>
    <div class="mr-1d5 ml-1d5 mb-2">
      <ul class="vcountries__list">
        <li class="vcountry" v-for="country, index in countedCountries" :key="country.flag">
            <span class="vcountry__position" :class="{'vcountry__position--first' : index === 0}">{{ parsePosition(index) }}</span>
            <img :src="'/flags/' + country.flag + '.svg'" :alt="country.flag" class="vcountry__flag">
            <div class="vcountry__text">
                <span class="vcountry__name">{{ country.country_name }}</span>
                <img v-if="index === 0" src="/eurovision.png" alt="Winner" class="country__winner">
                <!-- <span class="vcountry__title">{{ country.name }}</span> -->
            </div>
            <span class="vcountry__points">{{ country.points }}</span>
            <!-- <transition class="vcountry__vote" v-if="country.showPoints">{{ country.points }}</transition> -->
        </li>
      </ul>
    </div>
    <Loading :loading="loading"/>
</template>
  
<script>
import Loading from '../../components/Loading.vue';

const addPointsForCountry = (country, players) => {
  let counter = 0;
  players.forEach(player => {
    if(player["12s"] === country.id){
      counter += 12;
    }
    if(player["10s"] === country.id){
      counter += 10;
    }
    if(player["8s"] === country.id){
      counter += 8;
    }
    if(player["7s"] === country.id){
      counter += 7;
    }
    if(player["6s"] === country.id){
      counter += 6;
    }
    if(player["5s"] === country.id){
      counter += 5;
    }
    if(player["4s"] === country.id){
      counter += 4;
    }
    if(player["3s"] === country.id){
      counter += 3;
    }
    if(player["2s"] === country.id){
      counter += 2;
    }
    if(player["1s"] === country.id){
      counter += 1;
    }
  });

  return counter;
};

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Scoreboard',
    components: {
      Loading
    },
    data(){
      return {
        europarty: "",
        euroData: null,
        players: [],
        countries: [],
        countedCountries: [],
        loading:{
          loading: true
        }
      }
    },
    async created(){
      this.loading.loading = true;
      await this.axios.get("https://api.jciaurrant.com/country/final" , {
            headers: {
              "Content-Type": "application/json",
            }
        })
        .then(response => {
          this.countries = response.data;
        })
       .catch(function (error) {
          console.log(error);
      });
      
      if(this.$route.params?.token){
        this.europarty = this.$route.params.token;
      }

      if(this.europarty){
        let out = false;
        await this.axios.get("https://api.jciaurrant.com/europarty/" + this.europarty , {
            headers: {
              "Content-Type": "application/json",
            }
        })
        .then(response => {
          this.euroData = response.data;
          
          if(!this.euroData){
            // Posibles problemas si compartes el enlace para saber a quienes han votado en tu casa.
            localStorage.removeItem("currentEuroparty");
            out = true;
          }
  
        })
        .catch(function (error) {
          if(error.response.status === 404){
            localStorage.removeItem("currentEuroparty");
            out = true;
          }
        });
        
        if(out){
          this.loading.loading = false;
          this.$router.push('/');
        }

      }

      await this.axios.get("https://api.jciaurrant.com/player/" + this.europarty , {
            headers: {
              "Content-Type": "application/json",
            }
        })
        .then(response => {
          this.players = response.data;
  
        })
        .catch(function (error) {
            console.log(error);
        });

        const players = this.players;

        this.countedCountries = this.countries.map(country => {
          country.points = addPointsForCountry(country,players);
          return country;
        }).sort( (a, b) => { 
          if(a.points > b.points){
            return -1;
          }
          if(a.points < b.points){
            return 1;
          }

          return 0;
        })

        this.loading.loading = false;
    },
    methods: {
      parsePosition: function(position){
          return ('0' + (position+1)).slice(-2) + ".";
      },
      hasParty(){
        return !!localStorage.getItem('currentEuroparty') && !this.$route.params?.token;
      },
      inToken(){
        return !!this.$route.params?.token;
      },
      url(){
        return '/scoreboard/' + localStorage.getItem('currentEuroparty');
      }
    }
  }
</script>

<style scoped>
  .country__winner{
    display: block;
    width: 2rem; 
    margin-left: auto;
  }

  .vcountry__text{
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
  }
</style>