import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './pages/Home.vue'
import Countries from './pages/countries/index.vue'
import Country from './pages/countries/_.vue'
import Europarty from './pages/Europarty.vue'
import Scoreboard from './pages/scoreboard/_.vue'
import Vote from './pages/vote/_.vue'
import About from './pages/About.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'


// Router

const routes = [
    { path: '/', component: Home },
    { path: '/countries', component: Countries },
    { path: '/countries/:flag', component: Country },
    { path: '/europarty', component: Europarty },
    { path: '/scoreboard', component: Scoreboard },
    { path: '/scoreboard/:token', component: Scoreboard },
    { path: '/vote', component: Vote },
    { path: '/vote/:token', component: Vote },
    { path: '/about', component: About },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

const $app = createApp(App);

$app.use(router)
$app.use(VueAxios, axios)

$app.mount('#app')
