<template>
<div class="page">
  <header class="page__header">
    <img class="page__logo" alt="Europarty logo" src="./assets/logo.svg">
    <LateralMenu></LateralMenu>
  </header>
  <main class="page__container">
    <router-view></router-view>
  </main>
</div>
  
</template>

<script>
import LateralMenu from './components/LateralMenu.vue';

export default {
  name: 'App',
  components: {
    LateralMenu
  }
}
</script>

<style lang="scss">
  body, .page{
    background: $c-background;
  }

  .page{
    min-height: 100vh;
    color: $c-text;
    padding-top: 5rem;
    box-sizing: border-box;
    font-family: "OpenSans";

    &__header{
      display: flex;
      width: 100%;
      position: fixed;
      top: 0;
      background: $c-background;
      z-index: 2;
    }

    &__logo{
      margin-left: calc(2rem - 4px);
    }

    &__container{
      // padding: 0 2rem;
    }

    .app{
      &__button{
        background: $c-button;
        border: 0;
        display: block;
        color: $c-button-text;

        a {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            text-decoration: none;
            color: $c-button-text;
            padding: .5rem .75rem .6rem;
            z-index: 1;
        }

        svg{
            transform: none;
        }

        &--nobg{
          background: transparent;
          padding: 0;

          a{
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }

    .button__text{
        display: block;
    }
  }
</style>
