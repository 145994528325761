<template>
    <div v-show="loading.loading">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
    </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Loading',
    props: {
        loading:{
            type: Object,
            default: () => { return { loading: false}}
        }
    },
    data() {
        return {
        }
    }
  }
</script>

<style scoped>
div{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #04025e8d;
}
ul{
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: flex;
  margin: 0;
  padding: 0;
}
ul li
{
  list-style: none;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  animation: ani 1.6s ease-in-out infinite;
}
@keyframes ani {
  0%, 40%, 100%
  {
    transform: scale(0.2);
  }
  20%
  {
    transform: scale(0.6);
  }

}
ul li:nth-child(1)
{
  animation-delay: -1.2s;
  background: #FF0187;
  box-shadow: 0 0 50px #FF0187;

}
ul li:nth-child(2)
{
  animation-delay: -1s;
  background: #FFF700;
  box-shadow: 0 0 50px #FFF700;
}
ul li:nth-child(3)
{
  animation-delay: -.8s;
  background: #0043FF;
  box-shadow: 0 0 50px #0043FF;
}
</style>